function changePage(page) {
	var url = window.location.href.split("?")[0];
	var parameters = location.search.substring(1).split("&");

	var parameters = parameters.filter(function (value) {
		return value.indexOf("page=") == -1;
	});

	url += "?" + parameters.join("&") + "&page=" + page;

	window.location.href = url;
}

function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}
  
function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

$(document).ready(function () {


	$("body").on("click", ".glossary__term-description a", function (evt) {
		evt.stopPropagation();
	});

	// Pagination
	if ($('.pagination').length > 0) {
		$(function () {
			$('.pagination').pagination({
				pages: $('.pagination').data('pages'),
				currentPage: $('.pagination').data('current'),
				cssStyle: 'light-theme',
				selectOnClick: false,
				hrefTextPrefix: "#",
				prevText: String.fromCharCode(171),
				nextText: String.fromCharCode(187),
				onPageClick: changePage
			});
		});
	}
});


$(document).ready(function () {

	var tables = $('.htmlArea').find("table");

	tables.each(function (index) {
		$(this).wrap('<div class="collapsible-item collapsible-item--breakout ng-scope is-toggled"> <div class="table-wrapper"> <div class="table-wrapper__xshadow"> <div class="table-wrapper__responsive"> </div> </div> </div> </div>');
	});

	// add the "target" attribute in the links.
	function addTarget() {
		var links = $('.htmlArea').find("a");

		links.each(function (index) {
			var attr = $(this).attr('target');

			if (typeof attr === typeof undefined || attr == false) {
				$(this).attr('target', '_self');
			}
		});
	}

	addTarget();
	$.initialize(".note__content, .collapsible-item__content", function () {
		addTarget();
	});


	$('dl.expandable-content__inner-contain').each(function (index) {
		if ($(this).find('dt').length < 3) {
			$(this).addClass("shortInnerContain");
		}
	});

});


$(document).ready(function () {

	$("#grantArticle").change(function () {
		$(this).closest("form").submit();
	});

	$("#arrangement").change(function () {
		$(this).closest("form").submit();
	});

	$("#year").change(function () {
		$(this).closest("form").submit();
	});

	$("#typeCategory").change(function () {
		$(this).closest("form").submit();
	});

});

$(document).ready(function () {
	$(".auto-search").each(function () {
		var $el = $(this);
		$el.autocomplete({
			source: function (request, response) {
				$.ajax({
					url: "_/service/com.enonic.app.nfi/mmmAutoZComplete?w=" + request.term,
					success: function (data) {
						response(data.data);
					}
				});
			},
			minLength: 2,
			select: function (event, ui) {
				var origEvent = event;
				while (origEvent.originalEvent !== undefined) origEvent = origEvent.originalEvent;

				if (origEvent.type == 'click') {
					$el.val(ui.item.value);
					$el.closest("form").submit();
				} else $el.closest("form").submit();

				return false;
			}
		});
	});

});

$(document).ready(function () {
	if (location.pathname == "/nokkelbegreper") {
		if ($(location.hash).length) {
			var tag = $(location.hash);
			tag.find("button").click();
			$('html,body').animate({
				scrollTop: tag.offset().top - 50
			}, 'slow');
		}
	}
});

$(document).ready(function () {
	const modalStickyFooter = new tingle.modal({
		footer: false,
		stickyFooter: false
	});

	const festivalItems = document.querySelectorAll('.festival__item');
	[].forEach.call(festivalItems, function (item) {
		item.addEventListener('click', function () {
			modalStickyFooter.setContent(item.querySelector('.modal-content').innerHTML);
			modalStickyFooter.open();
		});
	});

	modalStickyFooter.addFooterBtn('Add to Calendar', 'tingle-btn tingle-btn--primary tingle-btn--pull-left', function () {
		modalStickyFooter.close();
	});
	
	const modalMacro = new tingle.modal({
		footer: false,
		stickyFooter: false,
		cssClass: ['modal_macro_item__display']
  	});

	const macroItems = document.querySelectorAll('.modal_macro__item');
	[].forEach.call(macroItems, function (item) {
		if(!getCookie(item.id) ) {
		setCookie(item.id, true, 360)
		modalMacro.setContent(item.querySelector('.modal-content').innerHTML);
		modalMacro.open();
		}
	});
});
