"use strict";

app.directive('jbRotatingPlaceholder',['$timeout', function($interval) {
    return {
        link: function(scope, element, attrs) {
//            var placeholders = [
//                'Trollhunter',
//                "The King's Choice",
//                'Max Manus',
//                'Pyromaniac',
//                'The Eriksen Saga',
//                'Valkyrien',
//                'To Lose a World',
//                'Mr. Death',
//                'The Whisperers'
//            ];
        	var placeholders = [];
            var count = 1;
            
            var rotating = document.getElementById("rotatingId").getAttribute("data-rotating");
            var rotatingLabel = document.getElementById("rotatingId").getAttribute("data-rotating-label");
            
            if( rotating ){
            	placeholders = rotating.split(";");            	
            	rotatingLabel = (rotatingLabel)? rotatingLabel : "";

            	element[0].setAttribute('placeholder', rotatingLabel+' '+placeholders[0]);
	
	            var placeholderInterval = $interval(function() {
	                element[0].setAttribute('placeholder', rotatingLabel+' '+placeholders[count]);
	                count ++;
	                if (count >= placeholders.length) {
	                    count = 0;
	                }
	            }, 2000);
	
	            element.on('$destroy', function() {
	                $interval.cancel(placeholderInterval);
	            });
            
            }
        }
    };
}]);