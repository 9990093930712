// https://github.com/hiebj/ng-autofocus/blob/master/autofocus.js

'use strict';
    angular
    .module('autofocus', [])
    .directive('autofocus', ['$timeout', function($timeout) {
    	return {
    	    restrict: 'A',
    	    link: function($scope, $element, $attrs) {
    			    var dom = $element[0];
    			
    			    if ($attrs.autofocus) {
    			        $scope.$watch($attrs.autofocus, focusIf);
    			    } else {
    			        focusIf(true);
    			    }
    			
    			    function focusIf(condition) {
    			        if (condition) {
    			            $timeout(function() {
    			                dom.focus();
    			            }, $scope.$eval($attrs.autofocusDelay) || 0);
    			        }
    			    }
    			}
    	}		
    }]);


