"use strict";

app.controller('stepsController',[ '$scope', '$rootScope', 'scrollTo', '$timeout', function($scope, $rootScope, scrollTo, $timeout) {
    //console.log("test");
    
    // Changing steps in the form
    $scope.step = 0;
    $scope.lastStep = 0;

    $scope.setStep = function(val) {
            console.log("test");
        $scope.lastStep = angular.copy($scope.step);
        $scope.step = val;


        $timeout(function() {
            scrollTo(document.body, 0, 400);
        }, 100);
    };


    // Changing "sub" steps in the form
    $scope.subStep = 0;
    $scope.lastSubStep = 0;

    $scope.setSubStep = function(val) {
        $scope.lastSubStep = angular.copy($scope.subStep);
        $scope.subStep = val;
    };


    // Mock up using Enter / Return through the form
    $rootScope.$on('nfi.keyup', function(aEvent, event) {
        if(event.keyCode == 13) { // Enter (escape = 27)

            // console.log("Sdasd");
            if($scope.step === 0) {
                $scope.setStep(1);
                $scope.setSubStep(1.1);
            } else if ($scope.step === 1) {
                $scope.setStep(2);
                $scope.setSubStep(2.1);
            } else if ($scope.step === 3) {
                $scope.setStep(4);
                $scope.setSubStep(4.1);
            }
            else if ($scope.step === 4) {
                $scope.setStep(5);
                $scope.setSubStep(5.1);
            }
        }
    });
}]);