"use strict";

app.controller('pageController', ['$scope', '$http', '$rootScope', 'scrollTo', '$timeout', '$location', '$window', '$sce', function($scope, $http, $rootScope, scrollTo, $timeout, $location, $window, $sce) {

    // This directive is mostly for functions that are referred to only once on a page.

    // Show full navigation
    // $scope.navSiteIsShowing = false;
    //
    // $scope.toggleNavSite = function(value) {
    //     $scope.navSiteIsShowing = !$scope.navSiteIsShowing;
    //
    //     if($scope.searchIsToggled) {
    //         $scope.searchIsToggled = false;
    //     }
    //
    //     // $timeout(function() {
    //     //     scrollTo(document.body, 0, 400);
    //     // }, 300);
    // };

    $scope.navType = false;

    $scope.toggleNavSite = function(value) {
        $scope.navType = ($scope.navType === value) ? false : value;

        // console.log($scope.navType);
        // console.log($scope.navSiteVisibility);
    };

    $scope.glossaryIsShowing = false;
    $scope.glossaryTerm = null;

    $scope.toggleGlossary = function(value, term) {
        $scope.glossaryIsShowing = ($scope.glossaryIsShowing === value) ? false : value;

        $scope.glossaryTerm = term;
    };


    
    $rootScope.$on('nfi.keyup', function(aEvent, event) {
        if(event.keyCode == 27) { // Escape
            $scope.navType = false;
            $scope.glossaryIsShowing = false;
            $scope.$apply();
        }
    });


    // Show search
    $scope.searchIsToggled = false;

    $scope.toggleSearch = function(value) {
        $scope.searchIsToggled = !$scope.searchIsToggled;

        if($scope.navSiteIsShowing) {
            $scope.navSiteIsShowing = false;
        }

        if (value) {
            $scope.search = value;
        } else {
            $scope.search = null;
        }

        $timeout(function() {
            scrollTo(document.body, 0, 400);
        }, 300);
    };

    // $rootScope.$on('nfi.keyup', function(aEvent, event) {
    //     if(event.keyCode == 27) { // Escape
    //         $scope.navSiteIsShowing = false;
    //         $scope.searchIsToggled = false;
    //         $scope.$apply();
    //     }
    // });

    $scope.theme = {
        cinema: false,
        application: false,
        bluescreen: false,
        greenscreen: false
    };
    

    
    
    
    // Steps in tilskudd page
    $scope.listStep = [];
    $scope.loadingStep = false;
    $scope.deadlineList = false;
    $scope.menuList = true;
    $scope.bdShow = true;
    $scope.bottomDescription = null;
    
    // Changing steps in the form
    $scope.setStep = function( id, back )
    {
    	$scope.loadingStep = true;
    	
		var listElement;
		var stepList = document.getElementsByClassName( 'nav-choices' );
		if( stepList.length > 0 )
		{
			Array.prototype.forEach.call( stepList, function( list )
			{
				listElement = angular.element( list );
				listElement.addClass( 'ng-loading' );
				$scope.listStep = [];
			});
		}
    	
    	if ( id == false )
    	{
    		id = angular.element( document.getElementById( 'folderId' ) ).val();
    		$scope.menuList = true;
    	}
    	else $scope.menuList = false;
    	
		if ( back !== true )
		{
			if ( angular.element( document ).find( 'title' ).text() ) var title = angular.element( document ).find( 'title' ).text();
			else var title = 'NFI';
			
			$location.url( $location.path() +"?id="+ id );
			$location.replace();
			$window.history.pushState( { url: "?id="+id }, title, $location.path() +"?id="+ id );					
		}
		
    	if ( id )
    	{
			var url = "/_/service/com.enonic.app.nfi/getStepItems?stepId="+ id;
			$http.get( url ).success( function( data )
			{
				if ( data.count > 0 )
				{
					var stepList = document.getElementsByClassName( 'stepList' );
					if( stepList.length > 0 )
					{
						Array.prototype.forEach.call( stepList, function( list )
						{
							var defaultListElement = angular.element( list );
							defaultListElement.remove();
						});
					}

					data.hits.forEach( function( item )
					{
						if ( item.type == 'com.enonic.app.nfi:grant_article'
							|| item.type == 'com.enonic.app.nfi:category'
							|| item.type == 'com.enonic.app.nfi:folder'
							|| item.type == 'base:folder' )
						{
							$scope.listStep.push( item );
						}
					});
					
					if ( $scope.menuList ) angular.element( document.getElementById( 'page-title' ) ).html( data.subtitle );
					else
					{
						var title = angular.element( document.getElementById( 'page-title' ) ).html();
						var titleArray = title.split( " til " );
						
						angular.element( document.getElementById( 'page-title' ) ).html( titleArray[0] +" til "+ data.subtitle.toLowerCase() );
					}

					$scope.deadlineList = data.deadlineList;
					$scope.bottomDescription = $sce.trustAsHtml( data.bottomDescription );
					listElement.removeClass( 'ng-loading' );
					$scope.loadingStep = false;
					$scope.bdShow = false;
				}
				else
		    	{
					$scope.deadlineList = false;
					$scope.bottomDescription = null;
		    		listElement.removeClass( 'ng-loading' );
		        	$scope.loadingStep = false;
		        	$scope.bdShow = false;
		    	}
			});
    	}
    	else
    	{
    		listElement.removeClass( 'ng-loading' );
        	$scope.loadingStep = false;
    	}
    };
    
    $scope.checkItem = function( item )
    {
    	if ( typeof item.url != "undefined" ) $window.location.href = item.url;
    	else $scope.setStep(item._id);
    };


    // Changing "sub" steps in the form
    $scope.subStep = 0;
    $scope.lastSubStep = 0;

    $scope.setSubStep = function(val) {
        $scope.lastSubStep = angular.copy($scope.subStep);
        $scope.subStep = val;
    };

    // Filter Grant list
    $scope.filterGrantArrangement = false;
    $scope.filterGrantArticle = false;
    $scope.filterGrantYear = false;
    $scope.filterGrantData = function( filter )
    {
    	switch ( filter.attr( 'id' ) )
    	{
    		case 'arrangement':
    			if ( filter.val() != '' ) $scope.filterGrantArrangement = filter.val();
    			else $scope.filterGrantArrangement = false;
    			
    			$scope.$apply();
    			
            	var grantCategory = document.getElementsByClassName( 'grantList' );
            	if( grantCategory.length > 0 )
            	{
            		$timeout( function()
            		{
            			Array.prototype.forEach.call( grantCategory, function( category )
       					{
            				var hasChildren = false;
            				var categoryElement = angular.element( category );
            				categoryElement.removeClass( 'is-hidden' );
            				Array.prototype.forEach.call( categoryElement.find( 'li' ), function( item )
       						{
            					var itemElement = angular.element( item );
            					if ( !itemElement.hasClass( 'ng-hide' ) ) hasChildren = true;
       						});
            				
            				if ( !hasChildren ) categoryElement.addClass( 'is-hidden' );
       					});
            		}, 500);            		
            	}
    		break;
    		
    		case 'grantArticle':
    			if ( filter.val() != '' ) $scope.filterGrantArticle = filter.val();
    			else $scope.filterGrantArticle = false;
    			
    			$scope.$apply();
    			
            	var grantArrangement = document.getElementsByClassName( 'grantArrangement' );
            	if( grantArrangement.length > 0 )
            	{
            		$timeout( function()
            		{
            			Array.prototype.forEach.call( grantArrangement, function( arrangement )
       					{
            				var total = 0;
            				var arrangementElement = angular.element( arrangement );
            				Array.prototype.forEach.call( arrangementElement.find( 'tr' ), function( item )
       						{
            					var itemElement = angular.element( item );
            					if ( itemElement.hasClass( 'grantItem' ) && !itemElement.hasClass( 'ng-hide' ) ) total = total + 1;
       						});
            				
            				arrangementElement.find( 'span' ).html( total );
       					});
            		}, 500);            		
            	}
    		break;
    		
    		case 'year':
    			if ( filter.val() != '' ) $scope.filterGrantYear = filter.val();
    			else $scope.filterGrantYear = false;
    			
    			$scope.$apply();
    			
            	var grantArrangement = document.getElementsByClassName( 'grantArrangement' );
            	if( grantArrangement.length > 0 )
            	{
            		$timeout( function()
            		{
            			Array.prototype.forEach.call( grantArrangement, function( arrangement )
       					{
            				var total = 0;
            				var arrangementElement = angular.element( arrangement );
            				Array.prototype.forEach.call( arrangementElement.find( 'tr' ), function( item )
       						{
            					var itemElement = angular.element( item );
            					if ( itemElement.hasClass( 'grantItem' ) && !itemElement.hasClass( 'ng-hide' ) ) total = total + 1;
       						});
            				
            				arrangementElement.find( 'span' ).html( total );
       					});
            		}, 500);            		
            	}
    		break;
    	}
    }

    // Convert string to slug
    function convertToSlug(Text)
    {
        return Text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    }    
    

    $scope.listNews = {
    	showMore: true,
    	loading: false
    };
    
    function arrayDiff(a1, a2)
    {
        var a = [], diff = [];

        for ( var i = 0; i < a1.length; i++ )
        {
            a[a1[i]] = true;
        }

        for ( var i = 0; i < a2.length; i++ )
        {
            if ( a[a2[i]] ) delete a[a2[i]];
            else a[a2[i]] = true;
        }

        for ( var k in a ) diff.push(k);

        return diff;
    };    
    
    function setUrl( queryArray )
    {
        var filter = [];

        for ( var k in queryArray )
        {
            if ( queryArray.hasOwnProperty( k ) && queryArray[k].toString().length > 0 ) filter.push( k +"="+ queryArray[k].toString() );
        }

    	if ( angular.element( document ).find( 'title' ).text() ) var title = angular.element( document ).find( 'title' ).text();
    	else var title = 'NFI';
        
        if ( filter.length > 0 )
        {
        	var filterString = "?"+ filter.join("&");
        	
			$location.url( $location.path() + filterString );
			$location.replace();
			$window.history.pushState( { url: filterString }, title, $location.path() + filterString );					
			
            return filterString;
        }
        else
        {
			$location.url( $location.path() );
			$location.replace();
			$window.history.pushState( { url: "?" }, title, $location.path() );
			
        	return "?";
        }
    }
    
    function getParameterByName( name, url )
    {
        if ( !url ) url = window.location.href;
        
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        
        if (!results) return null;
        if (!results[2]) return '';
        
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }    
    
	function resizeSelect( sel )
	{
		for ( var i=0; i < sel.options.length; i++ )
		{
			sel.options[i].title=sel.options[i].innerHTML;

			if ( i != sel.options.selectedIndex ) sel.options[i].innerHTML = '';
		}
		sel.blur();
	}
	
	function restoreSelect( sel )
	{
		for ( var i = 0; i < sel.options.length; i++)
		{
			sel.options[i].innerHTML = sel.options[i].title;
		}
		
		for ( var i=0; i < sel.options.length; i++ )
		{
			sel.options[i].title = sel.options[i].innerHTML;

			if ( i != sel.options.selectedIndex )
			{
				sel.options[i].innerHTML = '';
			}
		}
	}

    $scope.getNewsData = function( backButton, pagination, filter )
    {
		
    	if ( backButton !== false )
    	{
            var paramsArray = [];
    		var filters = backButton;
    		var defaultParams = ['from', 'to', 'category'];
    		
    		var filterArray = backButton.substring(1);
    		if ( filterArray.length > 0 )
    		{
    			filterArray = filterArray.split( '&' );
    			filterArray.forEach( function( filter )
    			{
    				filter = filter.split( '=' );

    				if ( filter[0] != 'page' )
    				{
    					paramsArray.push( filter[0] );
    					
    					var filterTag = document.getElementById( filter[0] );
    					var filterElement = angular.element( filterTag );
    					filterElement.val( filter[1] );
    					restoreSelect( filterTag );
    					filterTag.blur();
    				}
    			});
    		}
			var paramsDiff = arrayDiff( defaultParams, paramsArray );
			paramsDiff.forEach( function( filter )
    		{
				switch ( filter )
				{
					case 'from':
						var value = angular.element( document.getElementById( 'defaultFrom' ) ).val();
					break;
				
					case 'to':
						var value = angular.element( document.getElementById( 'defaultTo' ) ).val();
					break;
				
					case 'category':
						var value = "";
					break;
				}
				
				var filterTag = document.getElementById( filter );
				var filterElement = angular.element( filterTag );
				filterElement.val( value );
				restoreSelect( filterTag );
				filterTag.blur();
    		});
    	}
    	else
    	{
    		if ( pagination !== false )
    		{    			
    			var query = {
    					from: (document.getElementById( 'from' ) !== null ) ? angular.element( document.getElementById( 'from' ) ).val() : "",
    					to: (document.getElementById( 'to' ) !== null ) ? angular.element( document.getElementById( 'to' ) ).val() : "",
    					category: (document.getElementById( 'category' ) !== null ) ? angular.element( document.getElementById( 'category' ) ).val() : "",
    					page: pagination ? parseInt( angular.element( document.getElementById( 'page' ) ).val() ) + 1 : angular.element( document.getElementById( 'page' ) ).val()
    			};
    		}
    		else
    		{	
    			var query = {};
    			if ( filter.val() ) query[ filter.attr( 'id' ) ] = filter.val();
    			
    			if ( filter.attr( 'id' ) != 'from' )
    			{
    				var tmp = getParameterByName( 'from' );
    				if ( tmp ) query['from'] = tmp;
    			}
    			
    			if ( filter.attr( 'id' ) != 'to' )
    			{
    				var tmp = getParameterByName( 'to' );
    				if ( tmp ) query['to'] = tmp;
    			}
    			
    			if ( filter.attr( 'id' ) != 'category' )
    			{
    				var tmp = getParameterByName( 'category' );
    				if ( tmp ) query['category'] = tmp;
    			}
    		}
    		
    		var filters = setUrl( query );
    	}
    	
        var url = angular.element( document.getElementById( 'url' ) ).val() + filters +'&path='+ angular.element( document.getElementById( 'path' ) ).val();

        if ( !pagination )
        {
        	var listTag = document.getElementsByClassName( 'list-news' );
        	if( listTag.length > 0 )
        	{
    			Array.prototype.forEach.call( listTag, function( list )
       			{
        			var listElement = angular.element( list );
        			listElement.empty();
        		});
        	}
        }
        
        $scope.listNews.showMore = false;
        $scope.listNews.loading = true;
        
        $http.get( url ).success( function( data )
        {
            if( data.articleList.length > 0 )
            {
	            data.articleList.forEach( function( article )
	            {
                    var articleObj = '<li class="list-news__item"><article><a href="'+ article.url +'" target="_self">';
                    if ( article.image ) articleObj += '<img src="'+ article.image +'" />';
                    articleObj += '<h2 class="list-news__title">'+ article.title +'</h2><time class="list-news__date">'+ article.publishDate +'</time></a>';
                    if ( typeof article.intro != "undefined" ) articleObj += '<span>'+ article.intro +'</span>';
                    articleObj += '</article></li>';

                	var listTag = document.getElementsByClassName( 'list-news' );
                	if( listTag.length > 0 )
                	{
            			Array.prototype.forEach.call( listTag, function( list )
            			{
                			var listElement = angular.element( list );
                   			listElement.append( articleObj );
                		});
                	}
	            });
	            
	            angular.element( document.getElementById( 'page' ) ).val( data.page );
	            $scope.listNews.loading = false;
                
                if ( data.next ) $scope.listNews.showMore = true;
            }
            else $scope.listNews.loading = false;
        });
    }
    
    $scope.showMoreNews = function()
    {
    	$scope.getNewsData( false, true );
    }
    
    $scope.listSearchResult = {
    	showMore: true,
    	loading: false
    };
    
    $scope.getSearchResultData = function( backButton, pagination, filter )
    {
    	if ( backButton !== false )
    	{
            var paramsArray = [];
    		var filters = backButton;
    		var defaultParams = ['from', 'to', 'text'];
    		
    		var filterArray = backButton.substring(1);
    		if ( filterArray.length > 0 )
    		{
    			filterArray = filterArray.split( '&' );
    			filterArray.forEach( function( filter )
    			{
    				filter = filter.split( '=' );

    				if ( filter[0] != 'page' )
    				{
    					paramsArray.push( filter[0] );
    					
    					if ( filter[0] != 'text' )
    					{
	    					var filterTag = document.getElementById( filter[0] );
	    					var filterElement = angular.element( filterTag );
	    					filterElement.val( filter[1] );
    						restoreSelect( filterTag );
    						filterTag.blur();
    					}
    				}
    			});
    		}
			var paramsDiff = arrayDiff( defaultParams, paramsArray );
			paramsDiff.forEach( function( filter )
    		{
				switch ( filter )
				{
					case 'from':
						var value = angular.element( document.getElementById( 'defaultFrom' ) ).val();
					break;
				
					case 'to':
						var value = angular.element( document.getElementById( 'defaultTo' ) ).val();
					break;
				
					case 'text':
						var value = angular.element( document.getElementById( 'defaultText' ) ).val();
					break;
				}
				
				var filterTag = document.getElementById( filter );
				var filterElement = angular.element( filterTag );
				filterElement.val( value );
				if ( filter[0] != 'text' )
				{
					restoreSelect( filterTag );
					filterTag.blur();
				}
    		});
    	}
    	else
    	{
    		if ( pagination !== false )
    		{
    			var query = {
    					from: angular.element( document.getElementById( 'from' ) ).val(),
    					to: angular.element( document.getElementById( 'to' ) ).val(),
    					text: angular.element( document.getElementById( 'defaultText' ) ).val(),
    					page: pagination ? parseInt( angular.element( document.getElementById( 'page' ) ).val() ) + 1 : angular.element( document.getElementById( 'page' ) ).val()
    			};
    		}
    		else
    		{
    			var query = {};
    			if ( filter.val() ) query[ filter.attr( 'id' ) ] = filter.val();
    			
    			if ( filter.attr( 'id' ) != 'from' )
    			{
    				var tmp = getParameterByName( 'from' );
    				if ( tmp ) query['from'] = tmp;
    			}
    			
    			if ( filter.attr( 'id' ) != 'to' )
    			{
    				var tmp = getParameterByName( 'to' );
    				if ( tmp ) query['to'] = tmp;
    			}
    			
				var tmp = getParameterByName( 'text' );
				if ( tmp ) query['text'] = tmp;
    		}
    		
    		var filters = setUrl( query );
    	}
    	
        var url = angular.element( document.getElementById( 'url' ) ).val() + filters +'&path='+ angular.element( document.getElementById( 'path' ) ).val();

        if ( !pagination )
        {
        	var listTag = document.getElementsByClassName( 'list-search-results' );
        	if( listTag.length > 0 )
        	{
    			Array.prototype.forEach.call( listTag, function( list )
       			{
        			var listElement = angular.element( list );
        			listElement.empty();
        		});
        	}
        }
        
        $scope.listSearchResult.showMore = false;
        $scope.listSearchResult.loading = true;
    	
        $http.get( url ).success( function( data )
        {
            if( data.searchList.hits.length > 0 )
            {
	            data.searchList.hits.forEach( function( item )
	            {
					var itemObj = '';			
					switch (item.parameters.contentType) {
						case "employee":
							
							itemObj = '<li class="list-search-results__item">';
							itemObj += '<a class="list-search-results__link" href="'+ item.url +'" target="_self">';											
							itemObj += '<h2 class="list-search-results__heading">'+ item.title +'</h2>';
							itemObj += '<small class="list-search-results__url">'+ item.path.substr( 0, item.path.lastIndexOf('/') ) +'</small>';
							itemObj += '</a>';

							if ( typeof item.parameters.extraData != "undefined" )
							{
								itemObj += '<div class="image-text-block"><div class="image-text-block__body"><p>';
								itemObj += ( typeof item.parameters.extraData.position != "undefined" ) ? item.parameters.extraData.position + '<br>' : '';
								itemObj += ( typeof item.parameters.extraData.avdeling != "undefined" ) ? item.parameters.extraData.avdeling + '<br>': '';	
								itemObj += ( typeof item.parameters.extraData.phone != "undefined" ) ?item.parameters.extraData.phone + '<br>': '';
								itemObj += ( typeof item.parameters.extraData.mobile != "undefined" ) ? item.parameters.extraData.mobile + '<br>': '';
								itemObj += ( typeof item.parameters.extraData.email != "undefined" ) ? '<a href="mailto:' + item.parameters.extraData.email + '">' + item.parameters.extraData.email + '</a>' : '';
								itemObj += '</p></div>';
								itemObj += '<figure class="image-text-block__image  avatar">';
								itemObj += ( typeof item.parameters.extraData.image != "undefined" ) ? '<img src="' + item.parameters.extraData.image + '" alt="' + item.parameters.extraData.name + '">' : '';
								itemObj += '</figure></div>';
							}
							break;
						case "grant":

							if(item.parameters.extraData.disableFullView){
								var grantUrl = item.url.replace( item.path, item.parameters.extraData.grantListURL );
							}else{
								var grantUrl = item.url;
							}

							itemObj = '<li class="list-search-results__item">';
							itemObj += '<a class="list-search-results__link" href="'+ grantUrl +'" target="_self">';											
							itemObj += '<h2 class="list-search-results__heading">'+ item.title +'</h2>';
							itemObj += '<small class="list-search-results__url">'+ item.path +'</small>';
							itemObj += '</a>';

							if ( typeof item.parameters.extraData != "undefined" )
							{
								itemObj += '<div><dl class="">';
								itemObj += (item.parameters.extraData.language == "eng") ? '<dt>Producer</dt>' : '<dt>Produsent</dt>';
								itemObj += '<dd>' + item.parameters.extraData.producer + '</dd>';
								itemObj += (item.parameters.extraData.language == "eng") ? '<dt>Production company</dt>' : '<dt>Produksjonsselskap</dt>';
								itemObj += '<dd>' + item.parameters.extraData.productionCompany + '</dd>';
								itemObj += (item.parameters.extraData.language == "eng") ? '<dt>Director</dt>' : '<dt>Regissør</dt>';
								itemObj += '<dd>' + item.parameters.extraData.director + '</dd>';
								itemObj += (item.parameters.extraData.language == "eng") ? '<dt>Screenwriter</dt>' : '<dt>Manusforfatter</dt>';
								itemObj += '<dd>' + item.parameters.extraData.screenwriter + '</dd>';
								itemObj += '</dl></div>';
							}

							if ( typeof item.description != "undefined" ) 
								itemObj += '<div class="list-search-results__extract">'+ item.description +'</div>';

							break;
						default:

							itemObj = '<li class="list-search-results__item">';
							itemObj += '<a class="list-search-results__link" href="'+ item.url +'" target="_self">';											
							itemObj += '<h2 class="list-search-results__heading">'+ item.title +'</h2>';
							itemObj += '<small class="list-search-results__url">'+ item.path +'</small>';
							itemObj += '</a>';

							if ( typeof item.description != "undefined" ) itemObj += '<div class="list-search-results__extract">'+ item.description +'</div>';
					}

					itemObj += '</li>';

	            	var listTag = document.getElementsByClassName( 'list-search-results' );
	            	if( listTag.length > 0 )
	            	{
	        			Array.prototype.forEach.call( listTag, function( list )
	        			{
	            			var listElement = angular.element( list );
	    					listElement.append( itemObj );
	            		});
	            	}
	            });
	            
	            angular.element( document.getElementById( 'total' ) ).html( data.searchList.total );
	            angular.element( document.getElementById( 'page' ) ).val( data.page );
	            $scope.listSearchResult.loading = false;
                
                if ( data.next ) $scope.listSearchResult.showMore = true;
            }
            else $scope.listSearchResult.loading = false;
        });
    }
    
    $scope.showMoreSearchResult = function()
    {
    	$scope.getSearchResultData( false, true );
    }
    
    $window.addEventListener( 'popstate', function( event )
    {
    	var listTag = document.getElementsByClassName( 'list-news' );
    	if( listTag.length > 0 )
    	{
			if ( event.state != null ) var path = event.state.url;
			else
			{
				if ( location.search.length > 0 ) var path = location.search;
				else var path = "?";
			}
			
	    	$scope.getNewsData( path );
    	}

    	var listTag = document.getElementsByClassName( 'list-search-results' );
    	if( listTag.length > 0 )
    	{
			if ( event.state != null ) var path = event.state.url;
			else
			{
				if ( location.search.length > 0 ) var path = location.search;
				else var path = "?";
			}
			
	    	$scope.getSearchResultData( path );
    	}
    	
    	var step = document.getElementsByClassName( 'step' );
    	if( step.length > 0 )
    	{
    		var backId = false;
			if ( event.state != null )
			{
				backId = event.state.url.split( '=' );
				backId = backId[1];
			}
			else
			{
				if ( location.search.length > 0 )
				{
					backId = location.search.split( '=' );
					backId = backId[1];
				}
			}
			$scope.setStep( backId, true );
    	}
    });
    
    angular.element(document).ready(function () {
    	
    	// Move about film macro
    	var containerDom = document.getElementsByClassName("aboutFilm");
    	if(containerDom.length > 0) {
    		
    		Array.prototype.forEach.call(containerDom, function(container) {
    			
    			var containerElement = angular.element(container);
    			var aboutFilm = document.getElementById("aboutFilm");
    			
    			if(typeof aboutFilm != "undefined") {
    				
    				var aboutFilmElement = angular.element(aboutFilm);
    				
    				aboutFilmElement.detach();
    				containerElement.append(aboutFilmElement);
    			}
    		});
    	}
    	
    	// Move list fact macro
    	var containerDom = document.getElementsByClassName("listFacts");
    	if(containerDom.length > 0) {
    		
    		Array.prototype.forEach.call(containerDom, function(container) {
    			
    			var containerElement = angular.element(container);
    			var listFacts = document.getElementById("listFacts");
    			
    			if(typeof listFacts != "undefined") {
    				
    				var listFactsElement = angular.element(listFacts);
    				
    				listFactsElement.detach();
    				containerElement.append(listFactsElement);
    			}
    		});
    	}
    	
    	// Move list fact macro
    	/*
    	var containerDom = document.getElementsByClassName("deadline");
    	if(containerDom.length > 0) {
    		
    		Array.prototype.forEach.call(containerDom, function(container) {
    			
    			var containerElement = angular.element(container);
    			var listDeadline = document.getElementById("deadline");
    			
    			if(typeof listDeadline != "undefined") {
    				
    				var listDeadlineElement = angular.element(listDeadline);
    				
    				listDeadlineElement.detach();
    				containerElement.append(listDeadlineElement);
    			}
    		});
    	}
    	*/
    	
    	// Find anchors
    	$scope.anchors = [];
    	var autoSubMenu = document.getElementsByClassName("autoSubMenu");
    	if(autoSubMenu.length > 0) {
    		
    		var anchors = document.getElementsByTagName("h2");
    		if(anchors.length > 0) {
    			
    			Array.prototype.forEach.call(anchors, function(anchor) {
    				
    				var angularElement = angular.element(anchor);
    				
    				if (!angularElement.hasClass("accordionTitle")) {
    					
    					angularElement.attr("id", convertToSlug(angularElement.text()));

    					var obj = {
    							label: angularElement.text(),
    							link: "#"+ angularElement.attr("id")
    					}
    					
    					$scope.anchors.push(obj);
    				}
    			});
    		}
    	} else {
    		
    		var anchors = document.getElementsByClassName("macroAnchor");
    		if(anchors.length > 0) {
    			
    			Array.prototype.forEach.call(anchors, function(anchor) {
    				
    				var angularElement = angular.element(anchor);

    				var obj = {
    						label: angularElement.attr("data-title"),
    						link: "#"+ angularElement.attr("id")
    				}

    				$scope.anchors.push(obj);
    			});    	
    		}
    	}
    });
    
    
//  <<<<<<<<<<<<<<<<< Maxwel: Code Init >>>>>>>>>>>>>>>>>>>>>
//	Search the database
    
    $scope.filterSearchFilm = false;
    $scope.filterSearchFilmLoading = true;
    $scope.filterSearchFilmShowMore = false;
    $scope.getFSF = function(){        

    	if( $location.path() == "/eng/search-database" || $location.path() == "/search-database" || $location.path() == "/festival-page" || $location.path() == "/eng/festival-page"){
	    	var paramsUrl = $location.search();
	    	var searchFilmUrl = "_/service/com.enonic.app.nfi/filterSearchFilm";
	    	
	    	if( $location.path() == "/eng/search-database" ){
                paramsUrl.language = "en";
            }else if($location.path() == "/search-database"){
                paramsUrl.language = "no";
            }
            else if( $location.path() == "/eng/festival-page" ){
	    		paramsUrl.language = "en";
	    	}else if($location.path() == "/festival-page"){
	    		paramsUrl.language = "no";
	    	}
	    	
	    	if( $scope.filterSearchFilm ){
	    		var appendData = $scope.filterSearchFilm.data;
	    	}else{
	    		var appendData = false;
	    	}
	    	
	    	if( Object.getOwnPropertyNames(paramsUrl).length > 0 ){
	    		
		    	$http.get( searchFilmUrl, { "params": paramsUrl }).success( function( data ){
		    		
		    		$scope.filterSearchFilmShowMore = data.showMore;
		    		$scope.filterSearchFilm = data;
		    		
		    		$scope.filterSearchFilm.data.forEach(function(item){
		    			item.description = $sce.trustAsHtml( item.description );
		    		});
		    		
		    		if( appendData ){
		    			$scope.filterSearchFilm.data = appendData.concat( $scope.filterSearchFilm.data );
		    		}
		    		
		    		$scope.filterSearchFilmLoading = false;
		    		
				});
	    	}else{
	    		$scope.filterSearchFilmLoading = false;
	    	}
    	}
    	
    	if( $location.path() == "/eng/film-list" || $location.path() == "/film-list" ){
	    	var paramsUrl = $location.search();
	    	var searchFilmUrl = "_/service/com.enonic.app.nfi/filterFilmList";
	    	
	    	if( $location.path() == "/eng/film-list" ){
	    		paramsUrl.language = "en";
	    	}else if($location.path() == "/film-list"){
	    		paramsUrl.language = "no";
	    	}
	    	
	    	if( $scope.filterSearchFilm ){
	    		var appendData = $scope.filterSearchFilm.data;
	    	}else{
	    		var appendData = false;
	    	}
	    	
	    	if( Object.getOwnPropertyNames(paramsUrl).length > 0 ){
	    		
		    	$http.get( searchFilmUrl, { "params": paramsUrl }).success( function( data ){
		    		
		    		$scope.filterSearchFilmShowMore = data.showMore;
		    		$scope.filterSearchFilm = data;
		    		
		    		$scope.filterSearchFilm.data.forEach(function(item){
		    			item.description = $sce.trustAsHtml( item.description );
		    		});
		    		
		    		if( appendData ){
		    			$scope.filterSearchFilm.data = appendData.concat( $scope.filterSearchFilm.data );
		    		}
		    		
		    		$scope.filterSearchFilmLoading = false;
		    		
				});
	    	}else{
	    		$scope.filterSearchFilmLoading = false;
	    	}
    	}
    	
    };
    
       	
    $scope.getFSF();
    $scope.showMoreFSF = function(){
    	
    	$scope.filterSearchFilmLoading = true;
        $scope.filterSearchFilmShowMore = false;
        
    	var params = $location.search();
    	
    	if( "page" in params && params.page != "" && !isNaN(params.page) ){
    		var page = parseInt( params.page );
    		params.page = page + 1;
    	}else{
    		params["page"] = 2;
    	}
    		
    	$location.search( params );
    	
    	$scope.getFSF();
    };
    
//  <<<<<<<<<<<<<<<<< Maxwel: Code End >>>>>>>>>>>>>>>>>>>>>  
    
}]);