// Author: Jørgen Blindheim

"use strict";

app.directive('draggable', ['$document', '$window', '$timeout', '$interval', function($document, $window, $timeout, $interval) {
    return {
        replace: true,
        transclude: true,
        link: function(scope, element, attrs) {
            var startX,
                pageX,
                mouseStart,
                startTime,
                endTime,
                isActive   = false,
                x          = 0,
                min        = Math.max(
                    0,
                    ( ( element[0].offsetWidth - 1240 ) / 2 ) + 20 // + 20 is the modifier for the gutter
                ),
                slider     = angular.element(element[0].querySelector('.draggable__content')),
                maxWidth   = slider[0].offsetWidth - element[0].offsetWidth;

            function init() {
                setPosition(min);
                x = min;
            }

            function reInit() {
                min = Math.max(
                    0,
                    ( ( element[0].offsetWidth - 1240 ) / 2 ) + 20 // + 20 is the modifier for the gutter
                );
                maxWidth = slider[0].offsetWidth - element[0].offsetWidth;
            }

            function setPosition(x) {
                slider.css({
                    'transform'         : 'translateX(' + x + 'px)',
                    '-moz-transform'    : 'translateX(' + x + 'px)',
                    '-webkit-transform' : 'translateX(' + x + 'px)'
                });
            }

            function formula(xPos) {
                x = Math.min(
                    min,
                    Math.max(
                        xPos - startX,
                        -maxWidth
                    )
                );
            }

            function move(xPos) {
                formula(xPos);

                slider.addClass('draggable__content--active');
                slider.removeClass('draggable__content--release');
                setPosition(x);
            }

            function release(xPos) {
                slider.removeClass('draggable__content--active');
                slider.addClass('draggable__content--release');
                formula(xPos);
                setPosition(x);

                //console.log(xPos, mouseStart);

                var count = ( Math.max(0, 500 - Math.abs(startTime - endTime)) / 1000) * ( ( startX + xPos ) * 5 ) / 5,
                    direction = (xPos > mouseStart) ? 'right' : 'left';

                $timeout(function() {
                    if (direction == 'left') {
                        xPos -= count;
                    } else {
                        xPos += count;
                    }
                    formula(xPos);
                    setPosition(x);
                });
                $timeout(function() {
                    slider.removeClass('draggable__content--release');
                }, 600); // This needs to match the release animation time
            }
            function cancel() {
                slider.removeClass('draggable__content--active');
                // slider.removeClass('draggable__content--release');
            }

            // So I don't have to write this twice for mobile and desktop events
            var events = {
                start: function(xPos) {
                    isActive   = true;
                    startX     = xPos - x;
                    mouseStart = xPos;
                    startTime  = new Date();
                },
                move: function(xPos) {
                    pageX = xPos;
                    move(pageX);
                },
                end: function(xPos) {
                    isActive = false;
                    pageX    = xPos;
                    endTime  = new Date();

                    release(pageX);
                },
                cancel: function() {
                    isActive = false;
                    endTime  = new Date();
                    cancel();
                }
            };

            // Bind touch events
            // http://www.javascriptkit.com/javatutors/touchevents.shtml
            // https://developer.apple.com/library/ios/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html
            // http://stackoverflow.com/questions/9251590/prevent-page-scroll-on-drag-in-ios-and-android
            element[0].addEventListener('touchstart', function(event) {
                events.start(event.changedTouches[0].pageX);
                // event.preventDefault();
            }, false);
            document.addEventListener('touchmove', function(event) {
                if (isActive) {
                    events.move(event.changedTouches[0].pageX);

                    if (cancelMove) {
                        event.preventDefault();
                    }
                }
            }, false);
            document.addEventListener('touchend', function(event) {
                if (isActive) {
                    events.end(event.changedTouches[0].pageX);
                    // event.preventDefault();
                }
            }, false);

            // Bind click mouseevents
            element[0].addEventListener('mousedown', function(event) {
                events.start(event.pageX);
                // event.preventDefault();
            }, false);
            document.addEventListener('mousemove', function(event) {
                if (isActive) {
                    events.move(event.pageX);
                    event.preventDefault();
                }
            }, false);
            document.addEventListener('mouseup', function(event) {
                if (isActive) {
                    events.end(event.pageX);
                    // event.preventDefault();
                }
            }, false);

            angular.element($window).bind('resize', function() {
                reInit();
            });

            var checkScrolling = true,
                startPos,
                scrollDistance = 0;

            document.addEventListener('scroll', function(event) {
                if (checkScrolling) {
                    startPos = window.pageYOffset;
                    checkScrolling = false;

                    setTimeout(function() {
                        scrollDistance = Math.abs(startPos - window.pageYOffset);
                        checkScrolling = true;

                        if (scrollDistance > 20) {
                            events.cancel();
                        }
                    }, 100);
                }
            }, false);

            // Some init hacks...
            $timeout(function() {
                init();
            });
            $timeout(function() {
                reInit();
            }, 300);
        },
        template:   '<div class="draggable">' +
                        '<div class="draggable__content">' +
                            '<div ng-transclude></div>' +
                        '</div>' +
                    '</div>'
    };
}]);