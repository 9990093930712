// Confirm dialog
"use strict";
app.directive('selectFilter', ['$timeout', '$location', '$window',
	function($timeout, $location, $window) {
		return {
			link: function (scope, element, attr) {
				var resized = false,
					sel = element[0];
				
				function resizeSelect() {
					if (resized) return;

					for (var i=0; i<sel.options.length; i++) {
						sel.options[i].title=sel.options[i].innerHTML;

						if (i!=sel.options.selectedIndex) {
							sel.options[i].innerHTML = '';
						}
					}
					resized = true;
					sel.blur();
				}

				function restoreSelect() {
					if (!resized) return;

					for (var i=0; i<sel.options.length; i++) {
						sel.options[i].innerHTML=sel.options[i].title;
					}
					resized = false;
				}

				$timeout(function() {
					resizeSelect();
				});

				sel.addEventListener('focus', function() {
					restoreSelect();
				});
				sel.addEventListener('blur', function() {
					resizeSelect();
				});
				sel.addEventListener('change', function() {
					resizeSelect();
					
					var element = angular.element(sel);
					angular.element( document.getElementById( 'page' ) ).val( '1' );
			    	scope.getNewsData( false, false, element );
				});
			}
		};
}])