// Author: Jørgen Blindheim, with additional hacking from Will Hindson

/* Options in html:
    scroll-top-trigger = "false" // by default. By default, the scroll detection is trigger by the bottom of the browser. Setting this to true changes this to the top.
    scroll-keep-toggled = "false" // by default. Setting to true will keep true status even after the element is scrolled past.
    scroll-offset = "false" // by default. Offsets the y point at which the scroll detection is triggered.
    scroll-detect-once-only = "false" // by default. Setting to true will trigger the detection only one per page load. Good for introducing elements, but not having them animate again when scrolling back up
    scroll-height-wrapper = "true" // This will calculate the height of the element and create a wrapper around it so IF it is taken out of the flow with 'position: fixed;' there is no "jump"
*/

"use strict";

    angular.module('app')
        .directive('scrollSpy', ['$timeout',
        function($timeout) {
            return {
                scope: {
                    scrollSpy               : '=',
                    scrollTopTrigger        : '=',
                    scrollKeepToggled       : '=',
                    scrollOffset            : '=',
                    scrollDetectOnlyOnce    : '=',
                    scrollHeightWrapper     : '='
                },
                link: function(scope, element) {
                    // Define the variables we'll be using
                    var scrollPos, top, bottom, windowHeight,
                        scrollOffset = scope.scrollOffset ? parseInt(scope.scrollOffset) : 0,
                        done     = false,
                        wrapper  = false,
                        scrollDetectOnlyOnce   = scope.scrollDetectOnlyOnce ? true : false;

                    // Get the correct top and left position of any element
                    function getPos(el) {
                        for (var lx = 0, ly = 0;
                             el !== null;
                             lx += el.offsetLeft,
                             ly += el.offsetTop,
                             el = el.offsetParent);

                        return {
                            x: lx,
                            y: ly
                        };
                    }

                    scope.scrollSpy = false;

                    // For when fixing an element and taking it out of the page flow
                    if (scope.scrollHeightWrapper) {
                        var parent = element[0].parentNode;
                        wrapper = document.createElement('div');
                        parent.replaceChild(wrapper, element[0]);
                        wrapper.appendChild(element[0]);
                    }

                    // Initiate the top pos
                    function init() {
                        var height = element[0].offsetHeight;
                        top = getPos(element[0]).y;
                        bottom = height + top;

                        // For when fixing an element and taking it out of the page flow
                        if (scope.scrollHeightWrapper) {
                            wrapper.style.height = height + 'px';
                        }

                        scroll();

                    }

                    // On scroll function
                    function scroll() {
                        scrollPos = window.pageYOffset ||
                                    document.documentElement.scrollTop;

                        if (scope.scrollSpy && done && scrollDetectOnlyOnce) {
                            return;
                        }

                        // Bottom aligned by default / overwrite with "scroll-top-trigger = "true" in html
                        windowHeight = scope.scrollTopTrigger ? 0 : window.innerHeight;

                        if ((scrollPos + scrollOffset + windowHeight) < top ||
                            (!scope.scrollKeepToggled && (scrollPos + scrollOffset + windowHeight) > bottom)) {
                            scope.$apply(function() {
                                scope.scrollSpy = false;
                            });
                        } else {
                            done = true;
                            scope.$apply(function() {
                                scope.scrollSpy = true;
                            });
                        }
                    }

                    // Listeners
                    window.addEventListener('scroll', function() {
                        scroll();
                    });

                    window.addEventListener('resize', function() {
                		init();
                	});

                    // Run init
                    $timeout(init);
                }
            };
        }]);
