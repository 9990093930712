"use strict";

app.directive("dynamicKeywords", ['$compile', function($compile)
{
    // Replace keywords
    var keywords = document.getElementById("keywords");

    if( keywords ) {
    	
		var keywordsElement = angular.element(keywords);
		var keywordsObject = JSON.parse(keywordsElement.attr("data-keywords"));

	    var pageBody = document.getElementById("pageBody");
	    
	    if(typeof pageBody != "undefined") {
	
			var pageBodyElement = angular.element(pageBody);
			var originalHtml = pageBodyElement.html();
			var changedHtml = originalHtml;
			
			for(var index in keywordsObject) {
				
			    if(keywordsObject.hasOwnProperty(index)) {

			    	keywordsObject[index].forEach( function( word )
					{
			    		var mainRegex = new RegExp("<([^>\\s]*)[^<]*("+ word +").*?<(.*?)>","i");
			    		var matches = changedHtml.match(mainRegex);
			    		if ( matches !== null && matches.length > 0 )
			    		{
			    			if ( typeof matches[1] != "undefined" && matches[1] != "a" )
			    			{
			    				var regex = new RegExp("(^[\\W]?|[\\W])("+ word +")([\\W])","i");
			    				changedHtml = changedHtml.replace(regex, '$1<span href="#" class="glossary-term" ng-click="toggleGlossary(true, \''+ index +'\')">$2</span>$3');
			    			}
			    		}
					});
			    }
			}

			if(changedHtml != originalHtml)
			{
				return {
					template : changedHtml
				};
			}
			else
			{
				return {};
			}
	    }
		else
		{
			return {};
		}
    }
	else
	{
		return {};
	}
}]);