"use strict";

app.directive('willStickyNav',['$window', function($window) {

    function objectTop(obj) {
        var curtop = 0;

        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;

            } while (obj = obj.offsetParent);
        }
        return curtop;
    }

    return {
        restrict: 'A',
        link: function($scope, elem, attrs) {
            var startTopPos, navSticky, mainContent, footer;

            function init() {
                startTopPos = objectTop(elem[0]) - 0;
                navSticky = document.querySelector('.ng-reading-indicator-progress');
                mainContent = document.querySelector('.page-body');
                // console.log(mainContent);
            }

            init();

            var setPosition = function () {
                // Fix the in-page navigation underneath the top nav
                // Only show it when it's in the main content (not in the page header or footer)
                //if ($window.pageYOffset > startTopPos) {
                if ($window.pageYOffset > (objectTop(mainContent) + 0) && $window.pageYOffset ) {
                    //console.log(objectTop(mainContent));
                    elem.addClass('is-fixed');
                } else {
                    elem.removeClass('is-fixed');
                }
            };

            angular.element($window).bind('scroll', function() {
                // Uncomment if statement if don't want page navigation fixed on mobile
                // if ($window.innerWidth > 640) {
                    window.requestAnimationFrame(setPosition);
                // }
            });
        }
    };
}]);