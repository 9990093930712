// Confirm dialog
"use strict";
app.directive('stepFinishRender', ['$timeout',
	function($timeout) {
		return {
			link: function (scope, element, attr) {
	            if ( scope.$last === true )
	            {
	                $timeout( function ()
	                {
	                	element.parent().removeClass( 'ng-loading' );
	                });
	            }
			}
		};
}])