"use strict";

/*
    Generic toggle controller
*/

app.controller('toggleController', ['$scope', '$rootScope', function($scope, $rootScope) {
    // Basic controller

    $scope.isToggled = false;

    $scope.toggle = function() {
        $scope.isToggled = !$scope.isToggled;
    };

    $rootScope.$on('nfi.keyup', function(aEvent, event) {
        if(event.keyCode == 27) { // Escape
            // $scope.navSiteVisibility = false;
            $scope.$apply();
        }
    });

}]);