"use strict";

angular.module('angular-parallax', [
]).directive('parallaxBackground', ['$window', function($window) {

    function objectTop(obj) {
        var curtop = 0;

        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;

            } while (obj = obj.offsetParent);
        }
        return curtop;
    }

    return {
        restrict: 'A',
        transclude: true,
        template: '<div ng-transclude></div>',
        scope: {
            parallaxRatio: '@'
        },
        link: function($scope, elem, attrs) {
            var calcValY,
                parallaxFrame,
                offsetTop = objectTop(elem[0]);

            setInterval(function() {
                offsetTop = objectTop(elem[0]);
            }, 50);

            var setPosition = function () {
                calcValY = Math.round( (offsetTop - window.pageYOffset) * ($scope.parallaxRatio ? $scope.parallaxRatio : 1.1 ) );

                elem.css({
                    '-webkit-transform' : 'translateY(' + calcValY + 'px)',
                    '-ms-transform'     : 'translateY(' + calcValY + 'px)',
                    'transform'         : 'translateY(' + calcValY + 'px)'
                });
            };

            /*
                TODO: Implement requestAnimationFrame
                Not sure if its ready just yet..
                http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/

                http://css-tricks.com/using-requestanimationframe/

                General tips on parallax
                https://medium.com/@dhg/82ced812e61c

                https://gist.github.com/paulirish/1579671 <--- TODO: Use this


            */
            if (!Modernizr.touch) {
                angular.element($window).bind('load', function(e) {
                    setPosition();
                });

                window.addEventListener('scroll', function() {
                    window.requestAnimationFrame(setPosition);
                });
            }

        }
    };
}]);